import React from 'react';
import ReactPlayer from 'react-player';

const Section_thomas_yoann = () => {
    return (
      <div className="bg-white flex flex-col items-center h-screen p-4 justify-center">
        <p className="font-serif font-bold text-3xl">Thomas & Yoann</p>
        {/* <p className="font-sans text-xs text-opacity-10 text-center text-justify max-w-3xl m-4"> Nous avons eu un très bon contact avec Hugo pour le mariage de notre fils. Il est très sympa et à l'écoute.
Il filme très bien les principaux moments, il sait aussi capturer de multiples détails d'émotions, de complicités, de sourires et d'échanges de regards.
Tout cela en sachant se faire discret au milieu des invités.
Le rendu est magnifique et de qualité, son film nous permettra de garder un merveilleux souvenir de cette belle journée.
    Nous le remercions infiniment. Bernadette et Bruno</p> */}
        <div className="w-full m-10 "><ReactPlayer url="https://www.youtube.com/watch?v=yjIn9717SyU" controls playing loop width="100%" /></div>
      </div>

    );
  };

  const Section_camille_florian = () => {
    return (
      <div className="bg-white flex flex-col items-center h-screen p-4 justify-center">
        <p className="font-serif font-bold text-3xl">Camille & Florian</p>
        {/* <p className="font-sans text-xs text-opacity-10 text-center text-justify max-w-3xl m-4"> Nous avons eu un très bon contact avec Hugo pour le mariage de notre fils. Il est très sympa et à l'écoute.
Il filme très bien les principaux moments, il sait aussi capturer de multiples détails d'émotions, de complicités, de sourires et d'échanges de regards.
Tout cela en sachant se faire discret au milieu des invités.
Le rendu est magnifique et de qualité, son film nous permettra de garder un merveilleux souvenir de cette belle journée.
    Nous le remercions infiniment. Bernadette et Bruno</p> */}
        <div className="w-full m-10 "><ReactPlayer url="https://www.youtube.com/watch?v=8dqqKnSsYTA" controls playing loop width="100%" /></div>
      </div>

    );
  };


const Section4 = () => {
    return (
        <div>
             <Section_thomas_yoann />
             <Section_camille_florian />
        </div>

    );
  };
  
  export default Section4;


  //https://vimeo.com/848196351
  //https://www.youtube.com/watch?v=bpcJUkW-7oI&t=7s

  /* */